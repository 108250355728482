import {
  SET_DOT,
  SET_LOADING,
  SET_CREDIT,
  SET_APPLICATION_LOADING,
} from "../Types";

export function setLoader(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_LOADING,
      payload: payload,
    });
  };
}

export function setApplicationLoader(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_APPLICATION_LOADING,
      payload: payload,
    });
  };
}

export function setDotFunc(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_DOT,
      payload: payload,
    });
  };
}

export function setCreditFunc(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_CREDIT,
      payload: payload,
    });
  };
}
