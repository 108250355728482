import React, { useState, useEffect } from "react";
import Icon from "../Icon";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { setLTMenu } from "../../../Redux/Actions/sidebarAction";

export default function LtSideBar({ callback, isCustomCheck, setIsCustom }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const { LTuser } = useSelector((state) => state.ltUser);
  const Menu = useSelector((state) => state.sideBarMenu.ltMenu);

  useEffect(() => {
    let updatedMenu = [
      {
        id: 1,
        isOpen: false,
        title: "Companies",
        icon: "icon-dashboard-inactive",
      },
      {
        id: 2,
        isOpen: false,
        title: "Flexport Endorsements",
        icon: "icon-flexport-logo",
      },
    ];

    if (process.env.NEXT_PUBLIC_ORGANIZATION === "Luckytruck") {
      updatedMenu = [
        {
          id: 1,
          isOpen: false,
          title: "Companies",
          icon: "icon-lt-logo",
        },
        {
          id: 2,
          isOpen: false,
          title: "Dashboard",
          icon: "icon-dashboard-inactive",
        },
        {
          id: 3,
          isOpen: false,
          title: "Learning",
          icon: "icon-lt-learning",
        },
        {
          id: 4,
          isOpen: false,
          title: "Endorsements",
          icon: "icon-endorsement",
          subMenu: [
            {
              subTitle: "LT Endorsements",
              isActive: false,
            },
          ],
        },
      ];
    }

    if (
      process.env.NEXT_PUBLIC_ORGANIZATION === "Luckytruck" &&
      (LTuser?.roles?.includes("SuperProducer") ||
        LTuser?.roles?.includes("Admin"))
    ) {
      updatedMenu = [
        {
          id: 1,
          isOpen: false,
          title: "Companies",
          icon: "icon-lt-logo",
        },
        {
          id: 2,
          isOpen: false,
          title: "Dashboard",
          icon: "icon-dashboard-inactive",
        },
        {
          id: 3,
          isOpen: false,
          title: "Endorsements",
          icon: "icon-endorsement",
          subMenu: [
            {
              subTitle: "LT Endorsements",
              isActive: false,
            },
            {
              subTitle: "Staxx Endorsements",
              isActive: false,
            },
            {
              subTitle: "Flexport Endorsements",
              isActive: false,
            },
          ],
        },
        {
          id: 4,
          isOpen: false,
          title: "Staxx Companies",
          icon: "icon-dashboard-inactive",
        },
        {
          id: 5,
          isOpen: false,
          title: "Flexport Companies",
          icon: "icon-flexport-logo",
        },
        {
          id: 6,
          isOpen: false,
          title: "Learning",
          icon: "icon-lt-learning",
        },
        {
          id: 7,
          isOpen: false,
          title: "Referral",
          icon: "icon-referral",
        },
      ];
    }

    dispatch(setLTMenu(updatedMenu));
  }, []);

  useEffect(() => {
    if (isCustomCheck) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isCustomCheck]);

  const handleSubMenuClick = (mainItem) => {
    if (!open && mainItem.title === "Endorsements") {
      setOpen(true);
      callback(!open);
    }

    let copySidebar = [...Menu];

    let updatedSidebar = copySidebar?.map((item) => {
      if (item.id === mainItem.id && item.title === "Endorsements") {
        let updatedSubmenu = item.subMenu.map((subItem, index) => {
          if (index === 0) {
            return {
              ...subItem,
              isActive: true, // Activate the selected subItem
            };
          } else {
            return {
              ...subItem,
              isActive: false, // Deactivate other subItems
            };
          }
        });

        return {
          ...item,
          isOpen: !item.isOpen,
          subMenu: updatedSubmenu,
        };
      } else if (item.id === mainItem.id) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    dispatch(setLTMenu(updatedSidebar));

    if (mainItem.title === "Companies") {
      router.push(process.env.NEXT_PUBLIC_LT_COMPANIES);
    } else if (mainItem.title === "Endorsements") {
      router.push(process.env.NEXT_PUBLIC_LT_TEAM_ENDORSEMENTS);
    } else if (mainItem.title === "Flexport Companies") {
      router.push(process.env.NEXT_PUBLIC_FLEXPORT_COMPANIES);
    } else if (mainItem.title === "Stax Companies") {
      router.push(process.env.NEXT_PUBLIC_LT_STAX_COMPANIES);
    } else if (mainItem.title === "Dashboard") {
      router.push(process.env.NEXT_PUBLIC_LT_HOME);
    } else if (mainItem.title === "Learning") {
      router.push(process.env.NEXT_PUBLIC_LT_TEAM_LEARNING);
    } else if (mainItem.title === "Referral") {
      router.push("/LTteam" + process.env.NEXT_PUBLIC_USER_REFERRAL);
    } else if (mainItem.title === "Flexport Endorsements") {
      router.push(process.env.NEXT_PUBLIC_LT_FLEXPORT_ENDORSEMENTS);
    }
  };

  const onClickSubMenu = (parentIndex, subMenuIndex) => {
    const updatedMenu = [...Menu];
    const updatedSubMenu = updatedMenu[parentIndex]?.subMenu?.map(
      (item, index) => ({
        ...item,
        isActive: index === subMenuIndex,
      })
    );
    updatedMenu[parentIndex] = {
      ...updatedMenu[parentIndex],
      subMenu: updatedSubMenu,
    };

    if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle ===
      "LT Endorsements"
    ) {
      router.push(process.env.NEXT_PUBLIC_LT_TEAM_ENDORSEMENTS);
    } else if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle ===
      "Staxx Endorsements"
    ) {
      router.push(process.env.NEXT_PUBLIC_LT_STAX_ENDORSEMENTS);
    } else if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle ===
      "Flexport Endorsements"
    ) {
      router.push(process.env.NEXT_PUBLIC_LT_FLEXPORT_ENDORSEMENTS);
    }

    dispatch(setLTMenu(updatedMenu));
  };

  return (
    <div
      className={`flex flex-col h-full mt-[4rem] rounded-r-lg fixed z-10 flex-wrap transition-all duration-300 xs:hidden ${
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "bg-[#383E4F]"
          : "bg-flexportNavbar"
      } ${open ? " w-ltSideBar" : "w-20"}`}
    >
      {/* <img
        src={`${
          !open
            ? `${
                process.env.NEXT_PUBLIC_THEME == "LT"
                  ? "/Images/Tab-open.webp"
                  : "/Images/flexport/Tab-open.webp"
              }`
            : `${
                process.env.NEXT_PUBLIC_THEME == "LT"
                  ? "/Images/Tab-close.webp"
                  : "/Images/flexport/Tab-close.webp"
              }`
        }`}
        onClick={() => {
          setOpen(!open);
          callback(!open);
          setIsCustom(false);
        }}
        alt="truck"
        className="absolute cursor-pointer -right-10 top-3 w-12 h-12"
      /> */}

      <div className="mt-10">
        <ul className="flex flex-col" style={{ height: "85vh" }}>
          {Menu?.map((item, i) => (
            <>
              <li
                key={i}
                className={`text-white cursor-pointer mt-5 relative ${
                  item.isOpen && open && "onhover"
                } `}
                onClick={() => handleSubMenuClick(item)}
              >
                {item.isOpen && (
                  <div
                    className={`bg-blue5 w-2 h-8 rounded-l-none rounded-r-md absolute -top-1 ${
                      open ? "-left-5" : "left-0"
                    }`}
                  />
                )}
                <div
                  className={`flex items-center space-x-4 ${
                    !open ? "ml-6" : "ml-10"
                  }`}
                >
                  <Icon name={item.icon} />
                  <h3
                    className={`${!open && "hidden"} ${
                      item.isOpen ? "title-sidebar-show" : "title-sidebar"
                    } `}
                  >
                    {item.title}
                  </h3>
                  {item.subMenu && open && (
                    <Icon
                      name={item.isOpen ? "icon-close-menu" : "icon-open-menu"}
                    />
                  )}
                </div>
              </li>

              {item.subMenu && open && (
                <ul
                  className={`flex flex-col subMenu ${
                    item.isOpen && "showMenu"
                  }`}
                >
                  {item?.subMenu?.map((subMenu, subIndex) => {
                    return (
                      <li
                        key={subIndex}
                        onClick={() => onClickSubMenu(i, subIndex)}
                        className="relative  text-white mt-3 cursor-pointer ml-20"
                      >
                        <div className="flex items-center">
                          <h3
                            className={`${subMenu.isActive && "subMenuActive"}`}
                          >
                            {subMenu.subTitle}
                          </h3>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          ))}
          <div
            onClick={() => {
              setOpen(!open);
              callback(!open);
              setIsCustom(false);
            }}
            className={`flex mt-auto items-center space-x-4  ${
              !open ? "ml-6" : "ml-10"
            }`}
          >
            <Icon name={open ? "sidebar-close" : "sidebar-open"} />
            <h3 className={`${!open && "hidden"} text-white font-hkMedium  `}>
              Compress
            </h3>
          </div>
        </ul>
      </div>
    </div>
  );
}
