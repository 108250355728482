import { SET_FOLDERS, SET_SELECTED_FOLDER, CLEAR_FOLDER } from "../Types";

const initialState = {
  folders: [],
  selectedFolder: null,
};

const folderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case SET_SELECTED_FOLDER:
      const selectedFolder = state.folders.find(
        (folder) => folder.folderName === action.payload
      );

      return {
        ...state,
        selectedFolder: selectedFolder,
      };

    case CLEAR_FOLDER:
      return { ...initialState };
    default:
      return state;
  }
};

export default folderReducer;
