import React from "react";
import Notes from "../Notes";
import Badge from "../CounterBadge";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setNotesFlag } from "../../../Redux/Actions/companyActions";
import { selectNotesList } from "../../../Redux/Selectors/notesSelectors";
import {
  CLEAR_DATA,
  CLEAR_FOLDER,
  SET_LT_SIDEBAR,
  SET_SIDEBAR,
} from "../../../Redux/Types";

const LTLoggedInUserBar = ({ openSidebar, LTuser }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const notes = useSelector(selectNotesList);
  const { company } = useSelector((state) => state.company);
  const { notesFlag } = useSelector((state) => state.company);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });

  // Extracting action types for better readability
  const clearDataAction = { type: CLEAR_DATA };
  const setSidebarAction = { type: SET_SIDEBAR };
  const setLtSidebarAction = { type: SET_LT_SIDEBAR };

  const setNotes = (value) => dispatch(setNotesFlag(value));
  const onExitAccess = () => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("mainToken");
      localStorage.removeItem("getTow");
      if (token) {
        localStorage.removeItem("mainToken");
        router.push(process.env.NEXT_PUBLIC_LT_TEAM_COMPANIES);

        // Dispatching multiple actions at once
        dispatch(clearDataAction);
        dispatch(setSidebarAction);
        dispatch(setLtSidebarAction);
        dispatch({ type: CLEAR_FOLDER });
      }
    }
  };

  return (
    <header
      className={`${
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "bg-stepperChecked text-black "
          : "bg-flextportColor"
      } sticky top-16   flex flex-col   uppercase z-10 shadow-md duration-300 lg:text-base xl:text-base  font-hkBold xs:font-hkMedium xs:text-sm
        ${
          isTabletOrMobile
            ? `justify-between px-2`
            : `xs:justify-center custom:text-xs custom5:text-xs sm:justify-between md:justify-between pr-20 xs:pr-4 gap-x-4 xs:pl-0 md:text-sm ${
                openSidebar ? "pl-72" : "pl-32"
              }`
        }`}
    >
      <div className="flex mt-2 flex-row items-center xs:justify-center custom:text-xs custom5:text-xs justify-between  gap-x-4 xs:pl-0">
        <div className="mt-[3.5px]">{`BROKER VIEW: ${LTuser?.name}`}</div>
        <div className=" mt-[3.5px] hidden md:block">{`LOGGED IN AS ${
          company?.accountName ?? "Incomplete Lead"
        }`}</div>
        <div className="flex gap-16 relative">
          {/* Notes Section */}
          <div
            onClick={() => setNotes(notesFlag == null ? true : !notesFlag)}
            className="flex flex-row gap-3 items-center justify-center cursor-pointer bg-white text-black rounded-md px-2 py-0.5"
          >
            <h4 className="mt-[3.5px]"> NOTES</h4>
            <Badge
              counter={notes.length}
              color="bg-badgeColor"
              text="text-inActiveTab"
            />
          </div>
          {notesFlag && (
            <Notes
              company={company}
              showNotes={setNotes}
              notesFlag={notesFlag}
            />
          )}

          {/* Exit Section */}
          <div
            onClick={onExitAccess}
            className="flex flex-row items-center justify-center space-x-2 cursor-pointer bg-white text-black rounded-md px-3"
          >
            <h4 className="text-center mt-[3.5px]"> EXIT</h4>
          </div>
        </div>
      </div>
      <div className="flex  mt-2">
        <div className="  md:hidden">{`LOGGED IN AS ${
          company?.accountName ?? "Incomplete Lead"
        }`}</div>
      </div>
    </header>
  );
};

export default LTLoggedInUserBar;
