import React from "react";
import Icon from "../Icon";

export default function Button({
  icon,
  title,
  width,
  height,
  disabled,
  color = "",
  onButtonClick,
  textColor = "",
  additionStyle = "",
  ...rest
}) {
  const handleClick = (e) => {
    if (!disabled) {
      onButtonClick(e);
    }
  };

  return (
    <div className={`relative ${width}`}>
      <button
        type="button"
        {...rest}
        disabled={disabled}
        onClick={handleClick}
        className={`${width} ${height} ${additionStyle} ${color} ${textColor} gap-3
          ${
            disabled
              ? "InActiveButton cursor-default"
              : "activeButton cursor-pointer"
          }`}
      >
        <span className="flex items-center justify-center h-full align-middle mt-[3.5px]">
          {title}
        </span>
        {icon && <Icon name={icon} additionStyle="top-3" />}
      </button>
    </div>
  );
}
