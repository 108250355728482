import React from "react";
import Icon from "../Icon";
import Button from "../Button";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { notificationType } from "../../../utils/constants";
import { calculateTime } from "../../../Redux/Utils/notificationsUtils";
import {
  GET_COMPANY_NOTIFICATIONS_MUTATION,
  UPDATE_NOTIFICATION_STATUS_MUTATION,
} from "../../../pages/api/mutations";
import {
  updateNotification,
  updateNotificationWithBulkPayload,
} from "../../../Redux/Actions/notificationActions";
import {
  selectListPage,
  selectFetchMore,
  selectViewMoreLoader,
} from "../../../Redux/Selectors/notificationSelectors";

export default function NotificationComp({
  notifications,
  notificationTrayRef,
  setOpenNotificationTray,
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  // Selectors
  const viewMore = useSelector(selectFetchMore);
  const pageNumber = useSelector(selectListPage);
  const viewMoreLoading = useSelector(selectViewMoreLoader);

  // Mutations
  const [updateNotificationStatus] = useMutation(
    UPDATE_NOTIFICATION_STATUS_MUTATION
  );
  const [refetchNotificationList] = useMutation(
    GET_COMPANY_NOTIFICATIONS_MUTATION
  );

  const notificationClickHandler = async (n) => {
    setOpenNotificationTray(false);
    try {
      if (!n.seen) {
        await updateNotificationStatus({ variables: { id: n.id } }).then(
          (res) => {
            if (res) {
              dispatch(updateNotification(res.data.updateNotificationStatus));
              if (n.type === notificationType.mvrResponse) {
                if (router.pathname != process.env.NEXT_PUBLIC_USER_DASHBOARD) {
                  router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
                }
              }
            }
          }
        );
      } else {
        if (n.type === notificationType.mvrResponse) {
          if (router.pathname != process.env.NEXT_PUBLIC_USER_DASHBOARD) {
            router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
          }
        } else if (
          n.type === notificationType.policyCreated ||
          n.type === notificationType.endorsementResolved
        ) {
          if (router.pathname != process.env.NEXT_PUBLIC_USER_POLICY) {
            router.push(process.env.NEXT_PUBLIC_USER_POLICY);
          }
        } else if (
          n.type === notificationType.nonInstantQuoteUpdate ||
          n.type === notificationType.quoteQuoted
        ) {
          if (router.pathname != process.env.NEXT_PUBLIC_USER_QUOTES) {
            router.push(process.env.NEXT_PUBLIC_USER_QUOTES);
          }
        } else if (n.type === notificationType.dispatcherRequest) {
          if (router.pathname != process.env.NEXT_PUBLIC_SETTINGS_DISPATCHER) {
            router.push(process.env.NEXT_PUBLIC_SETTINGS_DISPATCHER);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadMoreNotificationsHandler = () => {
    refetchNotificationList({
      variables: {
        page: pageNumber,
      },
    }).then((res) => {
      dispatch(updateNotificationWithBulkPayload(res.data.notificationsList));
    });
  };

  const renderNotificationItem = (notification) => {
    if (notification.type === notificationType.mvrResponse) {
      return driverMVRNotification(notification);
    } else if (notification.type === notificationType.policyCreated) {
      return policyCreationNotification(notification);
    } else if (notification.type === notificationType.nonInstantQuoteUpdate) {
      return quoteNotification(notification);
    } else if (notification.type === notificationType.quoteQuoted) {
      return quoteQuotedNotification(notification);
    } else if (notification.type === notificationType.endorsementResolved) {
      return endorsementResolvedNotification(notification);
    } else if (notification.type === notificationType.quoteError) {
      return quoteErrorNotification(notification);
    } else if (notification.type === notificationType.dispatcherRequest) {
      return dispatcherNotification(notification);
    }
  };

  const driverMVRNotification = (n) => {
    return `${n.payload.driverfName ? n.payload.driverfName : ""} ${
      n.payload.driverlName ? n.payload.driverlName : "Driver"
    } MVR Report is ready with status ${n.payload.mvrStatus} !`;
  };

  const policyCreationNotification = (n) => {
    return !n.payload.policyNumber
      ? `Your policy from  ${n.payload.issuingCarrier} is almost ready!`
      : `Congrats your policy from  ${n?.payload?.issuingCarrier} is ready!`;
  };

  const endorsementResolvedNotification = (n) => {
    const issuingCarrier = n.payload.policy.issuingCarrier
      ? n.payload.policy.issuingCarrier
      : n.payload.policy[0].issuingCarrier;
    return `Your Endorsement ${n.payload?.name} is resolved! ${
      n.payload.policy ? `for "${issuingCarrier}"" policy` : ""
    }`;
  };

  const quoteNotification = (n) => {
    return `Your quote from  ${n.payload.issuingCarrier} is ready!`;
  };

  const quoteQuotedNotification = (n) => {
    return `Your quote from  ${n.payload.quoteCarrier} is ready!`;
  };

  const quoteErrorNotification = (n) => {
    return `There was error in your ${n.payload.quoteCarrier} application process`;
  };

  const dispatcherNotification = (n) => {
    return `A dispatcher has requested to access your account. Please click here to view the request`;
  };

  return (
    <div
      ref={notificationTrayRef}
      className="relative xs:w-64 w-80 mt-5 z-50 bg-white border-custom border-borderNoti box-border rounded-md arrow-top overflow-y-scroll max-h-72 h-fit py-3"
    >
      {notifications && notifications?.length > 0 ? (
        notifications?.map((item, index) => (
          <div
            key={index}
            className={`flex flex-row gap-2 py-2 font-semibold justify-center items-center ${
              item.seen && "text-gray11"
            }`}
            onClick={() => notificationClickHandler(item)}
          >
            <Icon name="icon-quote-active" />
            <div className="flex flex-col">
              <p className="font-hkRegular text-sm">
                {renderNotificationItem(item)}
              </p>
              <p className="font-hkRegular text-label">
                {calculateTime(item.createdAt)}
              </p>
            </div>
          </div>
        ))
      ) : (
        <h3 className=" text-center font-hkMedium text-base">
          No new notifications
        </h3>
      )}

      {viewMore && !viewMoreLoading && (
        <div className="flex justify-center mt-5">
          <Button
            width="w-40"
            height="h-10"
            title="View More"
            onButtonClick={loadMoreNotificationsHandler}
          />
        </div>
      )}
    </div>
  );
}
