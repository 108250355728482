import SideBar from "../Sidebar/sidebar";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import LTLoggedInUserBar from "../LTLoggedInUserBar";
import Icon from "../Icon";

const Layout1 = ({ children }) => {
  const router = useRouter();
  const [check, setCheck] = useState(true);
  const [openMobile, setOpenMobile] = useState(false);
  const [isCustomCheck, setIsCustom] = useState(false);
  const { LtLoggedIn, LTuser } = useSelector((state) => state.ltUser);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const isCustom = useMediaQuery({ minWidth: "481px", maxWidth: "700px" });
  const [wait, setWait] = useState(true);

  const initialMenuItems = [
    {
      title: "Home",
      icon: "mobile-home",
      navigateTo: process.env.NEXT_PUBLIC_USER_DASHBOARD,
      isSelected: true,
    },
    {
      title: "Assets",
      icon: "mobile-asset",
      navigateTo: process.env.NEXT_PUBLIC_USER_VEHICLE,
      isSelected: false,
    },
    {
      title: "Policies",
      icon: "mobile-policy",
      navigateTo: process.env.NEXT_PUBLIC_USER_POLICY,
      isSelected: false,
    },
    {
      title: "Quotes",
      icon: "mobile-quote",
      navigateTo: process.env.NEXT_PUBLIC_USER_QUOTES,
      isSelected: false,
    },
    {
      title: "Docs",
      icon: "mobile-document",
      navigateTo: process.env.NEXT_PUBLIC_USER_DOCUMENTS,
      isSelected: false,
    },
  ];
  const [menuItems, setMenuItems] = useState(initialMenuItems);

  useEffect(() => {
    const updateMenuItemsBasedOnPath = () => {
      const pathname = router.pathname;
      const routePathParts = pathname.split("/");
      const mainRoute = routePathParts[1];
      const subRoute = routePathParts[2] ? routePathParts[2] : null;
      let updatedMenuItems;
      if (mainRoute === "Dashboard" && !subRoute) {
        updatedMenuItems = menuItems?.map((item) =>
          item.navigateTo == process.env.NEXT_PUBLIC_USER_DASHBOARD
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
      } else if (
        mainRoute === "Dashboard" &&
        ["Vehicle", "Driver", "Commodity"].includes(subRoute)
      ) {
        updatedMenuItems = menuItems?.map((item) =>
          item.title == "Assets"
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
      } else if (mainRoute === "Dashboard" && subRoute == "Quotes") {
        updatedMenuItems = menuItems?.map((item) =>
          item.title == "Quotes"
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
      } else if (mainRoute === "Dashboard" && subRoute == "Policy") {
        updatedMenuItems = menuItems?.map((item) =>
          item.title == "Policies"
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
      } else if (mainRoute === "Documents" && subRoute == "Quotes") {
        updatedMenuItems = menuItems?.map((item) =>
          item.navigateTo == process.env.NEXT_PUBLIC_USER_QUOTES
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
      } else if (pathname === "/Documents") {
        updatedMenuItems = menuItems?.map((item) =>
          item.navigateTo == process.env.NEXT_PUBLIC_USER_DOCUMENTS
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
      } else {
        updatedMenuItems = menuItems?.map((item) => {
          return { ...item, isSelected: false };
        });
      }

      setMenuItems(updatedMenuItems);
    };

    updateMenuItemsBasedOnPath();
  }, [router.pathname]);

  const callback = (open) => {
    setCheck(open);
  };

  const openSidebarFunc = () => {
    setOpenMobile(!openMobile);
  };

  useEffect(() => {
    const mainToken = localStorage.getItem("mainToken");
    const token = localStorage.getItem("token");
    let withOutDot = localStorage.getItem("withOutDot");

    if (!mainToken && !withOutDot && !token) {
      router.push(process.env.NEXT_PUBLIC_USER_LOGIN);
    } else {
      setWait(false);
    }
  }, []);

  useEffect(() => {
    setCheck(true);
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (isCustom) {
      setIsCustom(true);
      setCheck(false);
    } else {
      setIsCustom(false);
      setCheck(true);
    }
  }, [isCustom]);

  const handleItemClick = (selectedItem) => {
    const updatedMenuItems = menuItems?.map((item) =>
      item.title === selectedItem.title
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false }
    );
    setMenuItems(updatedMenuItems);
    router.push(selectedItem.navigateTo);
  };

  return (
    <>
      {!wait && (
        <div className={`flex flex-col relative `}>
          <Navbar
            openSidebar={check}
            openSidebarFunc={openSidebarFunc}
            isCustomCheck={isCustomCheck}
          />

          {LtLoggedIn ? (
            <LTLoggedInUserBar openSidebar={check} LTuser={LTuser} />
          ) : null}

          <div className=" xs:block hidden w-full h-16 z-30 fixed bottom-0 bg-[#383E4F] ">
            <div className=" flex items-center mt-1 justify-between px-6">
              {menuItems?.map((item, i) => {
                return (
                  <>
                    <div
                      key={i}
                      onClick={() => handleItemClick(item)}
                      className={` text-white flex flex-col gap-y-1  justify-center items-center`}
                    >
                      <Icon fill={"#fff"} name={item.icon} />
                      <h3>{item.title}</h3>
                      {item.isSelected ? (
                        <div className=" h-1 w-full border-t-4  border-stepperChecked"></div>
                      ) : null}
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <SideBar
            callback={callback}
            isCustomCheck={isCustomCheck}
            setIsCustom={setIsCustom}
          />

          <div
            className={`flex-1 pb-10 ${
              check && !isCustomCheck ? "pl-64" : " pl-20"
            } duration-300 xs:pl-0 `}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
export default Layout1;
