import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import DownloadDocument from "../Modals/DownloadDocument";
import { brokerDetails } from "../../../utils/brokerDetails";
import { fileNameAndDateFormater } from "../../../utils/fileNameAndDateFormatter";
import { GET_COMPANY_DOCUMENT_FILE_URL_MUTATION } from "../../../pages/api/mutations";

const options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  month: "short",
  day: "numeric",
};

export const NotesList = ({ note, company }) => {
  // States
  const [fileUri, setFileUri] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openDownload, setOpenedDownload] = useState(false);
  const [accountOwner, setAccountOwner] = useState(brokerDetails[8]);

  useEffect(() => {
    if (company?.owner) {
      const filteredAccountOwner = brokerDetails?.filter(
        (broker) => broker?.name === company?.owner
      );
      if (filteredAccountOwner?.length)
        setAccountOwner(filteredAccountOwner[0]);
    }
  }, [company]);

  // Mutation
  const [fileUriMutation] = useMutation(GET_COMPANY_DOCUMENT_FILE_URL_MUTATION);

  const formattedDate = (inputDateString) =>
    new Intl.DateTimeFormat("en-US", options).format(new Date(inputDateString));

  const fileClickHandler = async (file) => {
    const fileUri = await fileUriMutation({
      variables: {
        key:
          process.env.NEXT_PUBLIC_NODE_ENV === "production"
            ? file.url.replace("lt-beta-data/", "")
            : file.url.replace("lt-zoho-stage/", ""),
      },
    });

    if (!fileUri.data.getCompanyDocumentsFileUrl.includes("file not found!")) {
      setSelectedFile(file);
      setFileUri(fileUri.data.getCompanyDocumentsFileUrl);
      setOpenedDownload(true);
    }
  };

  return (
    <div className="flex flex-row gap-3">
      <img
        alt="accountOwner"
        lang="eng"
        src={accountOwner?.image}
        className="w-10 h-10 rounded-full border-2 border-borderColor2"
      />

      <div>
        <p className="font-bold text-black">
          <b>{note.title}</b>
        </p>
        <p className="text-black font-hkLight pt-1">{note?.content}</p>
        {note?.files?.map((file, id) => (
          <div
            key={id}
            onClick={() => fileClickHandler(file)}
            className="flex gap-2 text-center text-bgInactiveButton font-hkBold text-sm cursor-pointer"
          >
            Attached File: <p className="font-hkLight">{file.name}</p>
            <a className="underline">Download File</a>
          </div>
        ))}
        <div className="flex flex-row gap-4 text-bgInactiveButton font-hkLight px-4">
          <p>{formattedDate(note.updatedAt)}</p>
          <p>by {note.modifiedByName}</p>
        </div>
      </div>

      {openDownload && (
        <DownloadDocument
          open={openDownload}
          onClose={setOpenedDownload}
          url={fileUri}
          name={
            selectedFile
              ? fileNameAndDateFormater(selectedFile?.name)?.name
              : ""
          }
          date={
            selectedFile ? fileNameAndDateFormater(selectedFile?.name).date : ""
          }
        />
      )}
    </div>
  );
};
