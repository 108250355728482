import React, { useEffect, useState } from "react";
import Badge from "../CounterBadge";
import NotesInput from "./NotesInput";
import { NotesList } from "./NotesList";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Actions/loadingAction";
import { GET_NOTES, INSERT_NOTE } from "../../../pages/api/mutations";
import { selectNotesList } from "../../../Redux/Selectors/notesSelectors";
import { setNotes, addNewNote } from "../../../Redux/Actions/notesActions";

export default function Notes({ company, showNotes, notesFlag }) {
  const dispatch = useDispatch();
  const notes = useSelector(selectNotesList);

  // States
  const [formFields, setFormFields] = useState({
    file: null,
    error: false,
    title: { value: "", error: false, message: "" },
    description: { value: "", error: false, message: "" },
  });

  // Mutations
  const [getNotes] = useMutation(GET_NOTES, {
    fetchPolicy: "network-only",
  });
  const [insertNote] = useMutation(INSERT_NOTE, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchNotes();
  }, []);

  const onInputChange = (e) => {
    if (e.stopPropagation) e.stopPropagation();

    const { value, name } = e.target;

    let someProperty = { ...formFields };
    someProperty[name].value = value;
    if (someProperty.error === true) {
      someProperty.error = false;
    }

    setFormFields(someProperty);
  };

  const fetchNotes = async () => {
    try {
      dispatch(setLoader(true));

      const moduleType =
        company?.isConverted && "isConverted" in company
          ? company.isConverted
            ? "accountId"
            : "leadId"
          : "accountId";
      const resp = await getNotes({
        variables: {
          module: moduleType,
          moduleId: company.id,
        },
      });

      dispatch(setNotes(resp.data.getNotes));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      console.log("error on fetching notes", error);
    }
  };

  const addNote = async () => {
    try {
      let someProperty = { ...formFields };
      if (!someProperty.title.value && !someProperty.description.value) {
        setFormFields({
          ...someProperty,
          error: true,
        });
        return;
      }

      dispatch(setLoader(true));

      const moduleType =
        "isConverted" in company
          ? company.isConverted
            ? "account"
            : "lead"
          : "account";
      const note = {
        module: moduleType,
        moduleId: company.id,
        title: someProperty.title.value,
        content: someProperty.description.value,
      };
      const resp = await insertNote({
        variables: { note: note },
      });

      if (resp.data.insertNote) {
        resetForm();
        dispatch(addNewNote(resp.data.insertNote));
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log("error on inserting notes", error);
    }
  };

  const resetForm = () => {
    setFormFields({
      file: null,
      title: { value: "", error: false, message: "" },
      description: { value: "", error: false, message: "" },
    });
  };

  return (
    <div className="notesCard normal-case">
      <div className="header flex">
        <div className="bg-white flex">
          <p className="heading">NOTES</p>
          <Badge
            counter={notes.length}
            color="bg-badgeColor"
            text="text-inActiveTab"
            absolute={true}
            top={"top-3"}
            left={"left-24"}
          />
        </div>
        <div className="action" onClick={() => showNotes(!notesFlag)}>
          <p> X</p>
        </div>
      </div>

      {/* display notes */}
      {notes?.length == 0 && (
        <div className="text-center text-gray9">No Notes</div>
      )}
      <div className="min-h-fit	max-h-52 overflow-scroll">
        {notes &&
          notes.length > 0 &&
          notes.map((note, id) => (
            <div className="px-5 py-3" key={id}>
              <NotesList note={note} company={company} />
            </div>
          ))}
      </div>

      <div className="px-5 pb-5 ">
        <NotesInput
          onSubmit={addNote}
          formFields={formFields}
          onCancelClick={resetForm}
          onInputChange={onInputChange}
        />
      </div>
    </div>
  );
}
